import { eventNames, trackEvent } from "utils/instrumentation";

export default function useOpenPricing() {
  const track = (instrumentation) => {
    trackEvent(eventNames.PRICING_CLICKED, {
      ...(instrumentation || {}),
      origin: window.location.href,
    })
  }

  return (instrumentation) => {
    track(instrumentation);
    window.open('/pricing', '_blank')
  };
}

/*
import useOpenPricing from 'assets/CustomHooks/use-open-pricing';
const openPricing = useOpenPricing();
openPricing({ button_name: 'header', feature:'pricing' });
*/
